import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import SanityImage from 'components/SanityImage/SanityImage'
import PannableContainer from 'components/PannableContainer/PannableContainer'
import styles from './Loader.module.scss'
import useStore from 'store'
import { TRANSITION_TIME_MS } from 'components/Transition/Transition.js'
import gsap from 'gsap'
import useLoadAssets from 'hooks/use-load-assets'

export let TIMEOUT = 3000
if (process.env.NODE_ENV === 'development') TIMEOUT = 100

const Loader = ({ className, data }) => {
  const innerRef = useRef()
  const [percentComplete, setPercentComplete] = useState(0)
  const setPageIsTransitioning = useStore(state => state.setPageIsTransitioning)
  const setPageIsLoaded = useStore(state => state.setPageIsLoaded)
  const pageIsLoaded = useStore(state => state.pageIsLoaded)
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const intervalRef = useRef()
  const containerRef = useRef()
  const timeoutRef = useRef()
  const loadableImagesContainerRef = useRef()
  const [animationComplete, setAnimationComplete] = useState(false)
  const [loaderImageLoaded, setLoaderImageLoaded] = useState(false)
  const { loadAssets } = useLoadAssets()
  const slashAnimationTextRef = useRef()
  const slashAnimationIntervalRef = useRef()

  useEffect(() => {
    if (!loaderImageLoaded) return

    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current)
      setPercentComplete(1)
    }, TIMEOUT)
  }, [loaderImageLoaded])

  useEffect(() => {
    if (!containerRef.current || pageIsTransitioning || !loaderImageLoaded) return

    if (percentComplete === 1) {
    }
  }, [percentComplete, setPageIsTransitioning, pageIsTransitioning, loaderImageLoaded])

  useEffect(() => {
    if (!innerRef.current || !loaderImageLoaded) return

    gsap.to(innerRef.current, {
      scaleX: 1,
      duration: TIMEOUT / 1000,
      onComplete: () => {
        setPageIsTransitioning(true)
        setTimeout(() => {
          setAnimationComplete(true)
          setPageIsTransitioning(false)
        }, TRANSITION_TIME_MS)
      },
    })
  }, [loaderImageLoaded, setPageIsTransitioning])

  useEffect(() => {
    if (!loaderImageLoaded || pageIsLoaded) return

    setTimeout(async () => {
      loadAssets(() => {
        clearTimeout(timeoutRef.current)
        setPercentComplete(1)
        setPageIsLoaded(true)
      })
    }, 300)
  }, [loaderImageLoaded, setPageIsLoaded, pageIsLoaded, loadAssets])

  useEffect(() => {
    if (slashAnimationIntervalRef.current) {
      clearInterval(slashAnimationIntervalRef.current)
    }

    if (animationComplete) return

    let inc = 1
    slashAnimationIntervalRef.current = setInterval(() => {
      const leftOver = inc % 4
      const char = ['-', '/', '-', '\\']

      if (slashAnimationTextRef.current) {
        slashAnimationTextRef.current.innerText = char[leftOver]
      }

      inc++
    }, 150)
  }, [animationComplete])

  if (!data || animationComplete) return null

  return (
    <div
      className={classnames(styles.Loader, className, { [styles.loaderImageLoaded]: loaderImageLoaded })}
      ref={containerRef}
    >
      <PannableContainer
        className={styles.pannableContainer}
        secondaryDirectionRoom={1.075}
        lerpLevel={0.09}
      >
        <SanityImage
          sizes={`${data.image.asset.metadata.dimensions.aspectRatio * 100}vw`}
          image={data.image}
          className={styles.image}
          onLoad={() => {
            setLoaderImageLoaded(true)
          }}
          unoptimized
        />
      </PannableContainer>
      <div
        ref={loadableImagesContainerRef}
        className={styles.loadableImageContainer}
      />
      <div className={styles.bar}>
        <div
          className={styles.bar__inner}
          ref={innerRef}
        />
        <span
          className={styles.bar__innerText}
          ref={slashAnimationTextRef}
        >
          \
        </span>
      </div>
    </div>
  )
}

Loader.displayName = 'Loader'

export default Loader
