import { useEffect } from "react";
import useNewKeyOnWindowResize from "./use-new-key-on-window-resize";

export default function useVhSizing() {
    const key = useNewKeyOnWindowResize(100, true);

    useEffect(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, [key]);

    return {};
}
