import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import Layout from 'components/Layout/Layout'
import Head from 'components/Head/Head'

import useVhSizing from 'hooks/use-vh-sizing'

import 'normalize.css'
import 'styles/global.scss'

import { pageview } from 'utils/analytics'
import useStore from 'store'
import useNewKeyOnWindowResize from 'hooks/use-new-key-on-window-resize'
import { getDeviceInfo } from 'utils/detect'

const isBrowser = typeof window !== 'undefined'

function App({ Component, pageProps }) {
  const router = useRouter()
  const layoutRef = useRef()
  const setPrevPath = useStore(state => state.setPrevPath)
  const setDeviceInfo = useStore(state => state.setDeviceInfo)
  const setWindowInFocusKey = useStore(state => state.setWindowInFocusKey)
  const key = useNewKeyOnWindowResize()
  const prevPathRef = useRef(null)

  useVhSizing()

  useEffect(() => {
    const info = getDeviceInfo()
    setDeviceInfo(info)
  }, [setDeviceInfo, key])

  useEffect(() => {
    if (!setWindowInFocusKey) return

    document.addEventListener('visibilitychange', () => {
      setWindowInFocusKey(Math.random())
    })
  }, [setWindowInFocusKey])

  useEffect(() => {
    setTimeout(() => {
      prevPathRef.current = router.asPath
    }, 20)
  }, [router.asPath])

  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url)
      setPrevPath(prevPathRef.current)
    }
    router.events.off('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setPrevPath])

  useEffect(() => {
    if (isBrowser) {
      if (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')()
      }
    }
  }, [])

  return (
    <>
      <Head />
      <Layout
        {...pageProps}
        ref={layoutRef}
      >
        <Component {...pageProps} />
      </Layout>
    </>
  )
}

export default App
