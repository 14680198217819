import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useStore from '../../store'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useLoadAssets from 'hooks/use-load-assets'
import { TIMEOUT } from 'components/Loader/Loader'
import { useTransitionFix } from '../../hooks/use-transition-fix'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

export const TransitionContext = React.createContext({ url: null, isPageLoaded: false })

export const TRANSITION_TIME = 0.8
export const TRANSITION_TIME_MS = TRANSITION_TIME * 1000

const variants = {
  hidden: { opacity: 1 },
  enter: { opacity: 1 },
  exit: { opacity: 1 },
}

const getUrlFromPageData = pageData => {
  const slug = pageData?.slug?.current
  const type = pageData?._type

  if (!slug || !type) return ''

  if (slug === 'home') {
    return '/'
  }

  if (type === 'portfolioItem') {
    return `/work/${slug}/`
  }

  return `/${slug}/`
}

const getZIndexFromPageData = pageData => {
  const slug = pageData?.slug?.current
  const type = pageData?._type

  if (!slug || !type) return 1

  return type === 'portfolioItem' ? 1 : 2
}

const Transition = ({ children, pageData }) => {
  useTransitionFix()

  const router = useRouter()
  const setPageIsTransitioning = useStore(state => state.setPageIsTransitioning)
  const setPageIsLoaded = useStore(state => state.setPageIsLoaded)
  const prevPath = useStore(state => state.prevPath)
  const { loadAssets } = useLoadAssets()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!prevPath) {
      setTimeout(() => {
        setIsLoaded(true)
      }, TIMEOUT)
    } else {
      loadAssets(() => {
        setIsLoaded(true)
        setPageIsLoaded(true)
      })
    }
  }, [loadAssets, prevPath, setPageIsLoaded])

  return (
    <AnimatePresence
      mode="wait"
      initial={false}
    >
      <motion.div
        animate="enter"
        exit="exit"
        variants={variants}
        style={{ zIndex: getZIndexFromPageData(pageData), position: 'relative' }}
        onAnimationStart={state => {
          if (state === 'exit') {
            setIsLoaded(false)
            setPageIsTransitioning(true)
          }
          if (state === 'enter') {
            ScrollTrigger.refresh()
            setPageIsLoaded(false)
          }
        }}
        onAnimationComplete={state => {
          if (state === 'enter') {
            setPageIsTransitioning(false)
          }
        }}
        transition={{ duration: TRANSITION_TIME }}
        key={router.asPath}
      >
        <TransitionContext.Provider
          value={{
            url: getUrlFromPageData(pageData),
            isPageLoaded: isLoaded,
          }}
        >
          {children}
        </TransitionContext.Provider>
      </motion.div>
    </AnimatePresence>
  )
}
export default Transition
