export const fields = `
	_id,
	_key,
	_type,
	asset->
`

export const fragment = (name, extraFields = null) => `${name}{
	${extraFields !== null ? `${extraFields},` : ''}
	${fields}
}`

export default fragment
