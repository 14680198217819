import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './Wipe.module.scss'
import useStore from 'store'
import gsap from 'gsap'
import { TRANSITION_TIME } from 'components/Transition/Transition'

import BackgroundTexture from 'assets/images/background-texture.png'

export const WIPE_EASE = 'Power3.easeInOut'

const Wipe = ({ className, children, pageData }) => {
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const prevPath = useStore(state => state.prevPath)
  const [wipeComplete, setWipeComplete] = useState(false)
  const bgRefs = useRef([])
  const firedAnimation = useRef(false)
  const blackBgWipeRef = useRef()
  const loadingTextRef = useRef()
  const pageIsLoaded = useStore(state => state.pageIsLoaded)
  const portfolioItemHoverIndex = useStore(state => state.portfolioItemHoverIndex)
  const portfolioItemClicked = useStore(state => state.portfolioItemClicked)

  useEffect(() => {
    if (!bgRefs.current.length || firedAnimation.current || !pageIsTransitioning) return

    firedAnimation.current = true

    gsap.set(bgRefs.current, {
      clearProps: true,
    })

    const duration = TRANSITION_TIME
    const ease = WIPE_EASE

    gsap.fromTo(
      bgRefs.current,
      {
        '--left-y': '100%',
      },
      {
        '--left-y': '0%',
        duration,
        ease,
        stagger: 0.05,
      },
    )

    gsap.fromTo(
      bgRefs.current,
      {
        '--right-y': '100%',
      },
      {
        '--right-y': '0%',
        duration,
        ease,
        delay: 0.05,
        stagger: 0.05,
        onComplete: () => {
          setWipeComplete(true)
        },
      },
    )
  }, [pageIsTransitioning])

  useEffect(() => {
    if (!blackBgWipeRef.current) return

    const isHoveringOverPortfolioItem = portfolioItemHoverIndex !== false
    const isShowing = pageData?._type === 'portfolioItem' || isHoveringOverPortfolioItem || portfolioItemClicked

    let duration = TRANSITION_TIME
    if (isHoveringOverPortfolioItem) {
      const cssValue = getComputedStyle(document.body).getPropertyValue('--hover-touch-transition')
      duration = parseFloat(cssValue)
    }

    const delay = pageIsTransitioning ? TRANSITION_TIME : 0

    gsap.killTweensOf(blackBgWipeRef.current)

    gsap.to(blackBgWipeRef.current, {
      autoAlpha: isShowing ? 1 : 0,
      duration,
      delay,
      ease: 'Power1.easeOut',
    })
  }, [pageData, prevPath, portfolioItemHoverIndex, pageIsTransitioning, portfolioItemClicked])

  useEffect(() => {
    if (!loadingTextRef.current) return

    gsap.killTweensOf(loadingTextRef.current)

    gsap.to(loadingTextRef.current, {
      autoAlpha: pageIsLoaded ? 0 : 1,
      duration: pageIsLoaded ? 0.2 : 0.6,
      delay: pageIsLoaded ? 0 : 0.6,
    })
  }, [pageIsLoaded])

  return (
    <div
      className={classnames(
        styles.Wipe,
        className,
        { [styles.wipeComplete]: wipeComplete },
        { [styles.wipeStarting]: pageIsTransitioning },
      )}
    >
      <div
        ref={ref => (bgRefs.current[0] = ref)}
        className={classnames(styles.bg, styles.black)}
      />
      <div
        ref={ref => (bgRefs.current[1] = ref)}
        className={classnames(styles.bg, styles.inner)}
      >
        <div className={styles.children}>{children}</div>
        <div
          className={styles.blackBgWipe}
          ref={blackBgWipeRef}
        />
        <img
          src={BackgroundTexture.src}
          alt=""
          className={styles.bgTexture}
        />
        {prevPath && (
          <p
            ref={loadingTextRef}
            className={styles.loadingText}
          >
            Loading
          </p>
        )}
      </div>
    </div>
  )
}

Wipe.displayName = 'Wipe'

export default Wipe
