export const lerp = (currentValue, targetValue, ease) => currentValue + (targetValue - currentValue) * ease

export const simpleImagesPreload = ({ urls, onComplete, onProgress }) => {
  let loadedCounter = 0
  const toBeLoadedNumber = urls.length

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++
      if (onProgress) {
        onProgress(loadedCounter / toBeLoadedNumber)
      }
      if (loadedCounter === toBeLoadedNumber) {
        if (onComplete) onComplete()
      }
    })
  })

  function preloadImage(url, anImageLoadedCallback) {
    const img = new Image()
    img.onload = anImageLoadedCallback
    img.src = url
  }
}
