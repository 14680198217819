export const getDeviceInfo = () => {
  const isBrowser = typeof window !== 'undefined'

  const detect = {
    device: { isDesktop: true },
    browser: {},
    os: {},
    bots: {},
    isTouchDevice:
      isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0),
  }

  if (isBrowser) {
    detect.device = require('@jam3/detect').device
    detect.browser = require('@jam3/detect').browser
    detect.os = require('@jam3/detect').os
    detect.bots = require('@jam3/detect').bots
  }

  return detect
}

const detect = getDeviceInfo()

export default detect
