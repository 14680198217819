import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './Cursor.module.scss'
import useStore from 'store'
import { useRouter } from 'next/router'
import gsap from 'gsap'

export const STATES = {
  static: 'static',
  hover: 'hover',
  hoverThumbnail: 'hoverThumbnail',
  gallery: 'gallery',
}

const STATES_IMAGES = {
  [STATES.static]: '/cursors/cursor--static.svg',
  [STATES.hover]: '/cursors/cursor--hover.svg',
  [STATES.hoverThumbnail]: '/cursors/cursor--hover--thumbnail.svg',
  [STATES.gallery]: '/cursors/cursor--gallery.svg',
}

const Cursor = ({ className }) => {
  const [currentState, setCurrentState] = useState(STATES.static)
  const [showCursor, setShowCursor] = useState(false)
  const deviceInfo = useStore(state => state.deviceInfo)
  const cursorType = useStore(state => state.cursorType)
  const showGalleryScrollIndicator = useStore(state => state.showGalleryScrollIndicator)
  const isVideoPortfolioItem = useStore(state => state.isVideoPortfolioItem)
  const isGalleryPortfolioItem = useStore(state => state.isGalleryPortfolioItem)
  const containerRef = useRef()
  const router = useRouter()
  const videoProgressRef = useRef()
  const videoProgressSubRef = useRef()
  const scrollIndicatorRef = useRef()
  const isWorkPage = router.asPath.includes('/work')

  useEffect(() => {
    const handleMouseOver = e => {
      if (!containerRef.current) return

      const x = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX
      const y = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY

      const transform = `translate(${x}px,${y}px)`

      containerRef.current.style.transform = transform

      if (scrollIndicatorRef.current && x !== 0 && y !== 0) {
        scrollIndicatorRef.current.style.transform = transform
        scrollIndicatorRef.current.style.display = 'block'
      }

      if (videoProgressRef.current && x !== 0 && y !== 0) {
        videoProgressRef.current.style.transform = transform
        videoProgressRef.current.style.display = 'block'
      }
    }

    if (!deviceInfo.isTouchDevice) {
      document.addEventListener('mousemove', handleMouseOver)
    }

    return () => {
      if (!deviceInfo.isTouchDevice) {
        document.removeEventListener('mousemove', handleMouseOver)
      }
    }
  }, [deviceInfo])

  useEffect(() => {
    if (isWorkPage) {
      return setCurrentState(STATES.gallery)
    }

    if (cursorType === null) {
      return setCurrentState(STATES.static)
    }

    if (!Object.keys(STATES).includes(cursorType)) return

    setCurrentState(cursorType)
  }, [cursorType, router.asPath, isWorkPage])

  useEffect(() => {
    setTimeout(() => {
      setShowCursor(true)
    }, 100)
  }, [])

  useEffect(() => {
    if (!scrollIndicatorRef.current || !isGalleryPortfolioItem) return
    gsap.killTweensOf(scrollIndicatorRef.current)
    gsap.to(scrollIndicatorRef.current, {
      autoAlpha: showGalleryScrollIndicator ? 1 : 0,
    })
  }, [showGalleryScrollIndicator, isGalleryPortfolioItem])

  useEffect(() => {
    if (videoProgressSubRef.current) {
      videoProgressSubRef.current()
    }

    if (!videoProgressRef.current || !isVideoPortfolioItem) return

    videoProgressSubRef.current = useStore.subscribe(
      state => state.videoProgress,
      state => {
        videoProgressRef.current.innerText = state.videoProgress
      },
    )

    return () => {
      if (videoProgressSubRef.current) {
        videoProgressSubRef.current()
      }
    }
  }, [isVideoPortfolioItem])

  return (
    <>
      <div
        className={classnames(
          styles.Cursor,
          className,
          { [styles[currentState]]: currentState },
          { [styles.isTouchDevice]: deviceInfo.isTouchDevice },
          { [styles.showCursor]: showCursor },
        )}
        ref={containerRef}
      >
        {Object.keys(STATES_IMAGES).map((key, i) => {
          const src = STATES_IMAGES[key]

          return (
            <img
              key={i}
              src={src}
              alt=""
              className={classnames(
                styles.cursorImage,
                { [styles[key]]: key },
                { [styles.isActive]: key === currentState },
              )}
            />
          )
        })}
      </div>
      {isVideoPortfolioItem && (
        <span
          className={styles.videoProgress}
          ref={videoProgressRef}
        />
      )}
      {isGalleryPortfolioItem && (
        <span
          className={styles.scrollIndicator}
          ref={scrollIndicatorRef}
        >
          Scroll
        </span>
      )}
    </>
  )
}

Cursor.displayName = 'Cursor'

export default Cursor
