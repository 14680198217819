import create from 'zustand'
import WindowSize from 'singletons/windowSize'
import breakpoints from 'utils/breakpoints'
import detect from 'utils/detect'
import { STATES as CURSOR_STATES } from 'components/Cursor/Cursor'

export const getBreakpoint = width => {
  if (width > 0) {
    for (const breakpoint of breakpoints) {
      if (width <= breakpoint.width) {
        return breakpoint
      }
    }
  }

  return breakpoints[breakpoints.length - 1]
}

export const UIStore = create(set => ({
  // Page transitions
  pageIsTransitioning: false,
  setPageIsTransitioning: pageIsTransitioning => set({ pageIsTransitioning }),
  pageIsLoaded: false,
  setPageIsLoaded: pageIsLoaded => set({ pageIsLoaded }),
  prevPath: null,
  setPrevPath: prevPath => set({ prevPath }),

  // Breakpoints
  breakpoint: getBreakpoint(WindowSize.getSize().width),
  setBreakpoint: breakpoint => set({ breakpoint }),

  // Home
  portfolioItemHoverIndex: false,
  setPortfolioItemHoverIndex: portfolioItemHoverIndex => set({ portfolioItemHoverIndex }),
  portfolioItemClicked: false,
  setPortfolioItemClicked: portfolioItemClicked => set({ portfolioItemClicked }),

  // Portfolio item
  isVideoPortfolioItem: false,
  setIsVideoPortfolioItem: isVideoPortfolioItem => set({ isVideoPortfolioItem }),
  isGalleryPortfolioItem: false,
  setIsGalleryPortfolioItem: isGalleryPortfolioItem => set({ isGalleryPortfolioItem }),
  centeredPortfolioItemIndex: -1,
  setCenteredPortfolioItemIndex: centeredPortfolioItemIndex => set({ centeredPortfolioItemIndex }),
  videoIsMuted: true,
  setVideoIsMuted: videoIsMuted => set({ videoIsMuted }),
  videoProgress: null,
  setVideoProgress: videoProgress => set({ videoProgress }),
  videoIsPlaying: true,
  setVideoIsPlaying: videoIsPlaying => set({ videoIsPlaying }),
  showGalleryScrollIndicator: false,
  setShowGalleryScrollIndicator: showGalleryScrollIndicator => set({ showGalleryScrollIndicator }),

  // cursor
  cursorType: CURSOR_STATES.static,
  setCursorType: cursorType => set({ cursorType }),

  // Hydration
  isStoreRehydrated: false,
  setIsStoreRehydrated: isStoreRehydrated => set({ isStoreRehydrated }),

  // Window
  windowInFocusKey: CURSOR_STATES.static,
  setWindowInFocusKey: windowInFocusKey => set({ windowInFocusKey }),

  // Device
  deviceInfo: {
    ...detect,
  },
  setDeviceInfo: deviceInfo => set({ deviceInfo }),
}))

export default UIStore
