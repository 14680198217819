/*
export const fields = `
	_id,
	_key,
	alt,
	preload,
	_type,
	asset->,
	crop,
	hotspot,
`
*/

export const fields = `
	_id,
	_key,
	alt,
	preload,
	_type,
	asset->{
		_id,
		_key,
		_type,
		alt,
		extension,
		metadata {
			lqip,
			dimensions {
				aspectRatio,
				height,
				width
			}
		},
		originalFilename,
		size,
		url
	}
`

export const fragment = (name, extraFields = null) => `${name}{
	${extraFields !== null ? `${extraFields},` : ''}
	${fields}
}`

export default fragment
