import { createClient } from 'next-sanity'
import { metaData, loader, modules, portfolioItem } from './fragments'

export const client = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: process.env.NEXT_PUBLIC_SANITY_USE_CDN === 'true' ? true : false,
})

export const getPageStaticPaths = async (type = 'page') => {
  const response = await client.fetch(`*[_type == "${type}"]`)

  const pathsData = response
    .map(page => {
      if (page.slug.current === '404') return

      return {
        params: { slug: page.slug.current },
      }
    })
    .filter(n => n)

  return pathsData
}

const getSlugParams = (type, slug) => {
  if (type === 'portfolioItem') {
    return `&& slug.current == "${slug}" && !(_id in path("drafts.**"))`
  } else if (type === 'page') {
    return `&& slug.current == "${slug}" && !(_id in path("drafts.**"))`
  }

  return ''
}

export const getPage = async (slug, type) => {
  /* eslint-disable */
  const allContent = await client.fetch(`
        *[_type == "settings" || _type == "${type}" ${getSlugParams(type, slug)}] {
          _type == "settings" => { 
            _type,
            siteTitle,
            ${metaData.fragment()},
            ${loader.fragment()}
          },
          ${
            type === 'page'
              ? `
            _type == "page" => { 
              _type,
              title,
              slug,
              ${metaData.fragment()},
              ${modules.fragment()}
            },
          `
              : ''
          }
          ${
            type === 'portfolioItem'
              ? `
            _type == "portfolioItem" => { 
              _type,
              title,
              slug,
              ${portfolioItem.fields}
            },
          `
              : ''
          }
        }
    `)
  /* eslint-enable */

  const siteSettings = allContent.filter(obj => obj._type === 'settings')[0]
  const allPageData = allContent.filter(obj => obj._type === type)
  const pageData = allPageData.filter(page => page.slug.current === slug)[0]

  if (!pageData) return null

  if (pageData.metaData) {
    Object.keys(pageData.metaData).forEach(key => {
      if (key === 'robots') return

      if (!pageData.metaData[key]) {
        delete pageData.metaData[key]
      }
    })
  }

  const data = {
    ...pageData,
    metaData: {
      ...siteSettings.metaData,
      ...pageData.metaData,
      title: `${siteSettings.siteTitle} ${pageData.title ? `| ${pageData.title}` : ''}`,
    },
    siteSettings,
  }

  return data
}

export default client
