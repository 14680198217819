import imageAsset from './imageAsset'

export const fields = `
	_type,
  description,
  keywords,
  robots,
  twitterHandle,
	${imageAsset('shareAsset')}
`

export const fragment = (name = 'metaData') => `${name}{ ${fields} }`

export default fragment
