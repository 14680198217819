if (typeof window !== 'undefined') window.dataLayer = window.dataLayer || []

/**
 * Dispatch an event with GTM
 *
 * @param {boolean} [action=false] - Action name
 * @param {any} [payload={}] - Action data
 */
function gtmEvent(action = false, payload = {}) {
  if (typeof window !== 'undefined' && action) {
    window.dataLayer.push({
      event: action,
      payload,
    })
  }
}

/**
 * GTM code snippet in body
 */
export const GtmNoScript = () => {
  return (
    <noscript>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <iframe
        title="gtm-container"
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </noscript>
  )
}

// log the pageview with their URL
export const pageview = url => {
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  })
}

// log specific events happening.
export const event = ({ action, params }) => {
  window.gtag('event', action, params)
}

const exports = { gtmEvent, GtmNoScript }

export default exports
