import * as fragments from './index.js'

const validTypes = [
  /* INJECT_TYPE */
  'homeSettings',
]

const typesMapped = validTypes
  .map(typeName => {
    if (fragments[typeName]) {
      return `_type == "${typeName}" => { ${fragments[typeName].fields} }\n`
    }
  })
  .join(',')

export const fragment = (name = 'modules') => `${name}[]->{
  module[] {
    ${typesMapped}
  }
}`

export default fragment
