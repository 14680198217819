import React, { useRef, useEffect } from 'react'
import Head from 'components/Head/Head'
import Transition from 'components/Transition/Transition'
import Loader from 'components/Loader/Loader'
import Wipe from 'components/Wipe/Wipe'
import Cursor from 'components/Cursor/Cursor'
import styles from './Layout.module.scss'
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useState } from 'react'

export const ScrollContext = React.createContext({ scroll: null, initScroll: () => {} })

export const SCROLL_CONTAINER_CLASS = styles.scrollContainer

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const Layout = React.forwardRef(({ children, data }, ref) => {
  const scrollContainerRef = useRef()
  const scrollContentRef = useRef()
  const [scrollInstance, setScrollInstance] = useState(null)
  const scrollRaf = useRef()
  const scrollRef = useRef(null)

  const initScroll = () => {
    if (!scrollContainerRef.current || !scrollContentRef.current) return

    setScrollInstance(null)
    if (scrollRaf.current) {
      cancelAnimationFrame(scrollRaf.current)
    }
    if (scrollRef.current) {
      scrollRef.current.destroy()
      scrollRef.current = null
    }

    scrollRef.current = new Lenis({
      duration: 1.6,
      smooth: true,
      mouseMultiplier: 0.8,
      smoothTouch: false,
      wrapper: scrollContainerRef.current,
      content: scrollContentRef.current,
    })

    setScrollInstance(scrollRef.current)

    function raf(time) {
      scrollRef.current.raf(time)
      requestAnimationFrame(raf)
      ScrollTrigger.update()
    }

    scrollRaf.current = requestAnimationFrame(raf)
  }

  useEffect(() => {
    initScroll()
  }, [])

  return (
    <ScrollContext.Provider
      value={{
        scroll: scrollInstance,
        initScroll,
      }}
    >
      <div
        className={styles.Layout}
        ref={ref}
      >
        <Head
          title={data?.metaData?.title}
          description={data?.metaData?.description}
          keywords={data?.metaData?.keywords}
          shareImageUrl={data?.metaData?.shareAsset?.asset?.url}
          robots={data?.metaData?.robots}
        />
        <Loader data={data?.siteSettings?.loader} />
        <main
          className={SCROLL_CONTAINER_CLASS}
          ref={scrollContainerRef}
        >
          <div
            className={styles.scrollContainerInner}
            ref={scrollContentRef}
          >
            <Wipe pageData={data}>
              <Transition
                pageData={data}
                scrollContainerRef={scrollContainerRef}
              >
                {children}
              </Transition>
            </Wipe>
          </div>
        </main>
        <Cursor />
      </div>
    </ScrollContext.Provider>
  )
})

Layout.displayName = 'Layout'

export default Layout
