import { useEffect, useState } from "react";

export default function useNewKeyOnWindowResize(
    debounceTimeMs = 200,
    allowHeight = false
) {
    const [previousWidth, setPreviousWidth] = useState(null);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (previousWidth === null) {
            setPreviousWidth(window.innerWidth);
            return;
        }

        let resizeTimeout = null;

        function handleResize() {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }

            resizeTimeout = setTimeout(() => {
                if (allowHeight) {
                    setPreviousWidth(window.innerWidth);
                    setKey(Date.now());
                } else if (window.innerWidth !== previousWidth) {
                    setPreviousWidth(window.innerWidth);
                    setKey(Date.now());
                }
            }, debounceTimeMs);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [previousWidth, debounceTimeMs, allowHeight]);

    return key;
}
