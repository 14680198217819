import imageAsset, { fields as imageAssetFields } from './imageAsset'
import fileAsset from './fileAsset'

export const fields = `
	_type,
  title,
  slug,
  hasExplicitContent,
  information {
    description,
    credits[] {
      title,
      names,
    },
    creditLogos[]{
      ${imageAssetFields}
    }
  },
  thumbnailOptions {
    imageSize,
    ${imageAsset('image')},
    ${fileAsset('videoLoop')}
  },
  type,
  images[]{
    ${imageAssetFields}
  },
  videoFileUrl,
  videoLength
`

export const fragment = (name = 'portfolioItem') => `${name}{ ${fields} }`
