import { useRef, useEffect, useCallback } from 'react'
import styles from './PannableContainer.module.scss'
import useNewKeyOnWindowResize from 'hooks/use-new-key-on-window-resize'
import { lerp } from 'utils'
import useStore from 'store'
import wait from '@jam3/wait'
import classnames from 'classnames'

const LERP_LEVEL = 0.2

const PannableContainer = ({ className, children, secondaryDirectionRoom = 1.4, lerpLevel }) => {
  const containerRef = useRef()
  const innerRef = useRef()
  const contentContainerRef = useRef()
  const rafRef = useRef()
  const maxRoomToPan = useRef({ x: 0, y: 0 })
  const deviceInfo = useStore(state => state.deviceInfo)
  const mousePositionTarget = useRef({ x: 0, y: 0 })
  const mousePositionCurrent = useRef({ x: 0, y: 0 })
  const requiresRafUpdate = useRef(false)

  const key = useNewKeyOnWindowResize()

  const initRaf = useCallback(() => {
    rafRef.current = requestAnimationFrame(initRaf)

    if (!containerRef.current) return

    if (requiresRafUpdate.current) {
      mousePositionCurrent.current.x = mousePositionTarget.current.x
      mousePositionCurrent.current.y = mousePositionTarget.current.y
    } else {
      mousePositionCurrent.current = {
        x: lerp(mousePositionCurrent.current.x, mousePositionTarget.current.x, lerpLevel || LERP_LEVEL),
        y: lerp(mousePositionCurrent.current.y, mousePositionTarget.current.y, lerpLevel || LERP_LEVEL),
      }
    }

    const percentFromLeft = mousePositionCurrent.current.x / containerRef.current.offsetWidth
    const percentFromTop = mousePositionCurrent.current.y / containerRef.current.offsetHeight

    const x = (1 - percentFromLeft * 2) * maxRoomToPan.current.x
    const y = (1 - percentFromTop * 2) * maxRoomToPan.current.y
    innerRef.current.style.transform = `translate(${x}px,${y}px)`

    requiresRafUpdate.current = false
  }, [lerpLevel])

  const killRaf = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
      rafRef.current = null
    }
  }

  useEffect(() => {
    const fire = async () => {
      await wait(10)

      if (!containerRef.current || !innerRef.current) return

      const containerAspectRatio = containerRef.current.offsetWidth / containerRef.current.offsetHeight
      const contentContainerAspectRatio =
        contentContainerRef.current.offsetWidth / contentContainerRef.current.offsetHeight

      innerRef.current.style.transform = 'translate(0px,0px)'
      innerRef.current.style.left = 0
      innerRef.current.style.top = 0
      innerRef.current.style.width = 'auto'

      if (containerAspectRatio < contentContainerAspectRatio) {
        const percentOfSize = (contentContainerAspectRatio / containerAspectRatio) * 100
        innerRef.current.style.width = `${percentOfSize * secondaryDirectionRoom}%`
        innerRef.current.style.top = `-${(secondaryDirectionRoom * 100 - 100) * 0.5}%`

        const xRoom = (contentContainerRef.current.offsetWidth - containerRef.current.offsetWidth) / 2
        maxRoomToPan.current.x = xRoom
        maxRoomToPan.current.y = (secondaryDirectionRoom * 100 - 100) * 0.5 * 0.01 * containerRef.current.offsetHeight

        innerRef.current.style.left = `${-xRoom}px`
      } else {
        innerRef.current.style.width = `${100 * secondaryDirectionRoom}%`
        innerRef.current.style.left = `-${(secondaryDirectionRoom * 100 - 100) * 0.5}%`

        const yRoom = (contentContainerRef.current.offsetHeight - containerRef.current.offsetHeight) / 2
        maxRoomToPan.current.y = yRoom
        maxRoomToPan.current.x = (secondaryDirectionRoom * 100 - 100) * 0.5 * 0.01 * containerRef.current.offsetWidth
        innerRef.current.style.top = `${-yRoom}px`
      }

      requiresRafUpdate.current = true
    }

    fire()
  }, [key, secondaryDirectionRoom])

  useEffect(() => {
    killRaf()
    initRaf()

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current)
    }
  }, [initRaf])

  useEffect(() => {
    const handleMouseOver = e => {
      const x = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX
      const y = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY
      mousePositionTarget.current = { x, y }
    }

    mousePositionTarget.current = {
      x: window.innerWidth * 0.5,
      y: window.innerHeight * 0.5,
    }

    if (deviceInfo.isTouchDevice) {
      document.addEventListener('touchmove', handleMouseOver)
    } else {
      document.addEventListener('mousemove', handleMouseOver)
    }

    return () => {
      if (deviceInfo.isTouchDevice) {
        document.removeEventListener('touchmove', handleMouseOver)
      } else {
        document.removeEventListener('mousemove', handleMouseOver)
      }
    }
  }, [deviceInfo])

  return (
    <div
      className={classnames(className, styles.PannableContainer)}
      ref={containerRef}
    >
      <div
        className={styles.inner}
        ref={innerRef}
      >
        <div
          className={styles.contentContainer}
          ref={contentContainerRef}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PannableContainer
