import imageAsset from './imageAsset'
import richText from './richText'
import cmsSettings from './cmsSettings'
import link from './link'
import { fields as portfolioItemFields } from './portfolioItem'

export const fields = `
	_type,
  ${cmsSettings()},
  logoSettings {
    ${imageAsset('desktopLogo')},
    ${imageAsset('desktopLogoHover')}
  },
  aboutModal {
    buttonText,
    ${richText('richTextContent')},
    ${imageAsset('image')},
    people {
      title,
      names[] {
        name,
        websiteLink
      }
    },
    contactMethods[] {
      type,
      ${link('link')}
    }
  },
  contact {
    email
  },
  footer {
    brandName,
    longText,
    copyright
  },
  portfolioItems[]-> {
    ${portfolioItemFields}
  }
`

export const fragment = (name = 'homeSettings') => `${name}{ ${fields} }`
